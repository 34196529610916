<template>
  <div class="main-container">
    <div class="background-area">
      <img src="../../assets/banner-1.jpg" alt="">
      <div class="caption">
          <h1>南棠ERP2.0</h1>
      </div>
    </div>

    <div>
      <img src="../../assets/function-intro/erp-sass/1-跨境电商运营一站式ERP.png" alt="跨境电商运营一站式ERP">
      <img src="../../assets/function-intro/erp-sass/2-采购管理.png" alt="采购管理">
      <img src="../../assets/function-intro/erp-sass/3-商品管理.png" alt="商品管理">
      <img src="../../assets/function-intro/erp-sass/4-发货管理.png" alt="商品管理">
    </div>

    <img src="../../assets/2-4.png" alt=""/>
    <!--  添加备案信息 -->
    <div class="record-keeping">
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024312059号-1</a>
    </div>
  </div>
</template>

<script>

export default {
  name: "ErpSass",
}
</script>

<style scoped>
  .background-area {
    position: relative;
  }
  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    color: white;
  }
</style>
